import { createApp } from 'vue'
import Login from './Login.vue'

// Ncode
import '@/assets/css/reset.css'
import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import '@/assets/css/style.css'

var app = createApp(Login).use(ElementPlus)

app.mount('#app')
