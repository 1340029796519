<template>
	<section>
		<img src="../../assets/img/logo.png" />
		<el-form v-bind="config.form" @submit.native.prevent="fn.formSubmit" :model="data.form" :rules="config.form.rules" ref="form">
			<el-form-item prop="usuario">
				<el-input size="default" placeholder="Ingrese usuario" v-model="data.form.usuario" @input="fn.formClearValidate"></el-input>
			</el-form-item>
			<el-form-item prop="clave">
				<el-input size="default" placeholder="Ingrese contraseña" v-model="data.form.clave" @input="fn.formClearValidate" type="password"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button native-type="submit" color="#0872c3" style="width: 100%">Iniciar sesión</el-button>
			</el-form-item>
		</el-form>
	</section>
</template>

<script>
	import { reactive, ref } from 'vue';
	import { ElLoading, ElMessage } from 'element-plus';

	export default {
		setup() {
			const form = ref(null);

			const fn = {
				formClearValidate() {
					form.value.clearValidate();
				},
				formSubmit() {
					form.value.validate(async (valid) => {
						if (valid) {
							const loading = ElLoading.service();

							const formData = new FormData();
							formData.append('username', data.form.usuario);
							formData.append('password', data.form.clave);

							var res = await fetch('/login', {
								method: 'post',
								body: formData,
							});

							if (res.status == 200) {
								res = await res.json();
								localStorage.setItem('token', res.token);

								window.location = '/';
							} else {
								loading.close();

								res = await res.json();
								ElMessage({
									message: res.error,
									type: 'error',
								});
							}
						} else {
							ElMessage({
								message: 'Verifique los datos del formulario',
								type: 'error',
							});
						}
					});
				},
			};

			/* DATA */
			const data = reactive({
				form: {
					usuario: '',
					clave: '',
				},
			});

			/* CONFIG */
			const config = {
				form: {
					rules: {
						usuario: [
							{
								required: true,
								message: 'Ingrese usuario',
								trigger: 'submit',
							},
						],
						clave: [
							{
								required: true,
								message: 'Ingrese contraseña',
								trigger: 'submit',
							},
						],
					},
				},
			};

			/* RETURN */
			return {
				form,
				fn,
				data,
				config,
			};
		},
	};
</script>

<style scoped>
	body,
	* {
		font-family: Arial;
	}

	:not(input):not(textarea) {
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		-o-user-select: none;
		user-select: none;
	}

	section {
		display: grid;
		height: 100vh;
		align-content: center;
		justify-content: center;
		background-image: url(../../../../backend/public/images/bg.jpg);
		background-position: center center;
		background-size: cover;
	}
</style>
